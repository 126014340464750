import React from 'react';
import { Link } from 'gatsby';
import ResponsiveMenu from 'react-responsive-navbar';
import styles from './nav.module.scss';
import SVG from 'react-svg';

const Nav = () => (
  <nav className={styles.root}>
    <ResponsiveMenu
      menuOpenButton={
        <SVG svgClassName={styles.openMenu} src={'/icons/menu_open.svg'} />
      }
      menuCloseButton={
        <SVG svgClassName={styles.closeMenu} src={'/icons/menu_close.svg'} />
      }
      changeMenuOn="768px"
      largeMenuClassName={styles.largeMenu}
      smallMenuClassName={styles.smallMenu}
      menu={
        <ul>
          <li>
            <Link to="/">Start</Link>
          </li>
          <li>
            <Link to="/about">About me</Link>
          </li>
          <li>
            <Link to="/portfolio">Portfolio</Link>
          </li>
          <li>
            <Link to="/blog">Blog</Link>
          </li>
          {/* <li>
            <Link to="/stories">Stories</Link>
          </li> */}
        </ul>
      }
    />
  </nav>
);

export default Nav;
