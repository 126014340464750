import React from 'react';
import PropTypes from 'prop-types';
import styles from './footer.module.scss';

const Footer = ({ children }) => (
  <footer className={styles.footer}>
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.column}>
          <p>
            Thanks for visiting. Copyrighted materials are copyrighted, unless
            they are not. &copy; 2015-2021
          </p>
          {children}
        </div>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  children: PropTypes.node,
};

export default Footer;
