import React from 'react';
import { Link } from 'gatsby';
import posed from 'react-pose';
import styles from './header.module.scss';
import Nav from 'components/header/nav';
import SVG from 'react-svg';

// Example of a component-specific page transition
const AnimatedContainer = posed.div({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-100%',
    transition: {
      ease: 'easeInOut',
    },
  },
});

const Header = () => (
  <AnimatedContainer>
    <div className={styles.root}>
      <div className={styles.container}>
        <Link to="/">
          <SVG svgClassName={styles.logo} src={'/icons/logo.svg'} />
        </Link>
        <Nav />
      </div>
    </div>
  </AnimatedContainer>
);

export default Header;
